<template>
  <v-card class="pa-4">
    <v-toolbar width="100%" class="mx-0" color="primary " dark>
      <v-card-text class="text-h5"> Attendance Update </v-card-text>
      <v-spacer> </v-spacer>
      <v-btn icon @click="$emit('closeDialog')">
        <v-icon large color="red lighten-2">mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-form v-on:submit.prevent="update" ref="form">
        <v-autocomplete
          :items="rooms"
          item-text="name"
          item-value="id"
          v-model="attandance.rooms"
          label="Class Room*"
          :rules="$requiredRules"
          multiple
        />

        <v-row style="background-color: azure">
          <v-row>
            <v-col>
              <v-menu
                ref="menu1"
                v-model="menu1"
                :rules="$requiredRules"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="start"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="attandance.start_time"
                    label="Start Time *"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="$requiredRules"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu1"
                  ampm-in-title
                  format="ampm"
                  color="red lighten-1"
                  v-model="attandance.start_time"
                  :max="end"
                  @click:minute="$refs.menu1.save(start_time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :rules="$requiredRules"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="end_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="attandance.end_time"
                    label="End Time *"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="$requiredRules"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu2"
                  ampm-in-title
                  format="ampm"
                  color="red lighten-1"
                  v-model="attandance.end_time"
                  :max="end"
                  @click:minute="$refs.menu2.save(attandance.end_time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              :items="graceTime"
              item-text="time"
              item-value="id"
              v-model="attandance.grace_time"
              label="Grace Time(min) *"
              :rules="$requiredRules"
              multiple
            />
          </v-col>
          <v-col>
            <v-autocomplete
              :items="attendtype"
              item-text="name"
              item-value="id"
              v-model="attandance.attend_type"
              label="Checkin/Checkoout *"
              :rules="$requiredRules"
              multiple
            />
          </v-col>

          <v-col>
            <v-select
              multiple
              chips
              v-model="attandance.days"
              label="Select day(s)"
              :items="day_list"
              item-text="name"
              item-value="id"
              :rules="$requiredRules"
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer> </v-spacer>
      <v-btn color="primary" outlined @click="update">Update</v-btn>
    </v-card-actions>
    <!-- </div> -->
  </v-card>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      day_list: [],
      dialog: false,
      rooms: [],
      menu1: false,
      menu2: false,
      menu3: false,

      days: "",
      dialogNewFee: false,
      room: "",
      start_time: null,
      end_time: null,
      attandance: {},
      graceTime: [],
    };
  },

  mounted() {
    this.loadGraceTime();
    this.loadAttendanceType();

    this.loadOwnedRooms();
    this.loadDays();
    this.loadAttend();
  },

  methods: {
    loadAttend() {
      this.$api.get(`/class_attandance/${this.item.id}`).then((response) => {
        this.attandance = response.data;
      });
    },
    loadOwnedRooms() {
      this.$api.get("/rooms/").then((res) => {
        this.rooms = res.data.results;
      });
    },
    loadDays() {
      this.$api.get("/attend-days/").then((res) => {
        this.day_list = res.data.results;
      });
    },

    cancel_dialog() {
      this.dialog = false;
    },
    update() {
      this.$api
        .put(`/class_attandance/${this.item.id}/`, this.attandance)
        .then((res) => {
          this.attandance = res.data;
        })
        .then(this.$emit("closeDialog"));
    },
    loadAttendanceType() {
      this.$api.get("/attendance_type/").then((res) => {
        this.attendtype = res.data.results;
      });
    },
    loadGraceTime() {
      this.$api.get("/grace_time/").then((res) => {
        this.graceTime = res.data.results;
      });
    },
  },
};
</script>

<style></style>
